import { Flexbox, Header, Paragraph } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import ParagraphTypes from '@stage-ui/core/content/Paragraph/types'
import HeaderTypes from '@stage-ui/core/content/Header/types'

type PageTitleProps = {
  title: React.ReactNode
  label?: string
  labelProps?: ParagraphTypes.Props
  titleProps?: HeaderTypes.Props
} & FlexboxTypes.Props

const PageTitle = (props: PageTitleProps) => {
  const { title, label, labelProps, titleProps, onClick, ...restProps } = props
  return (
    <Flexbox column mb="2rem" {...restProps}>
      {!!label && (
        <Paragraph
          size="0.825rem"
          color="gray600"
          // @ts-ignore
          style={{
            textTransform: 'uppercase',
            ...labelProps,
          }}
        >
          {label}
        </Paragraph>
      )}
      <Header
        mt={['m', 's']}
        size={['2.5rem', '1.5rem']}
        color="black"
        // @ts-ignore
        style={{
          ...titleProps,
        }}
      >
        {title}
      </Header>
    </Flexbox>
  )
}

export default PageTitle
